body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Makes sure that the shadow goes above the content */
footer,
header {
  z-index: 1000;
}

/* Note: there is no styleOverrides for MuiStack within MUI (at the moment). So in order to have
the proper background color for tracking rows, the main stack will use this class.
These should match the COLOURS.LightBlueBackground value in shared/.../colours.ts
*/
.patient-priority-background {
  background: #eaf2fb;
}
.patient-priority-background:nth-of-type(odd) {
  background: #eaf2fb80;
}

.formik-flex {
  display: flex;
}